import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { CommentAPIEndpoints } from '../common/endPoint';



export const postComment = createAsyncThunk('postComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.CreateComment(body.id),
        method: 'POST',
        body: body.formData,
        isFormData: true,
    };
    return await API(obj);
});

export const postCommentReply = createAsyncThunk('postCommentReply', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.Comments(body.id),
        method: 'POST',
        body: JSON.stringify(body.requestBody),
    };
    return await API(obj);
});

export const fetchComments = createAsyncThunk('fetchComments', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.Comments(body.id),
    };
    return await API(obj);
});

export const fetchDashboardComment = createAsyncThunk('fetchDashboardComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.dashbordComment(body.id),
    };
    return await API(obj);
});

export const deleteComment = createAsyncThunk('deleteComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.CommentDelete(body.id),
        method: 'DELETE',
    };
    return await API(obj);
});

export const editComment = createAsyncThunk('editComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.EditComment(body.id),
        method: 'PUT',
        body: body.formData,
        isFormData: true,
    };
    return await API(obj);
});

export const likeComment = createAsyncThunk('likeComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.CommentLike(body.id),
        method: 'PUT',
        body: JSON.stringify(body.requestBody),
    };
    return await API(obj);
});

export const disLikeComment = createAsyncThunk('disLikeComment', async (body) => {
    const obj = {
        url: CommentAPIEndpoints.CommentDisLike(body.id),
        method: 'DELETE',
        body: JSON.stringify(body.requestBody),
    };
    return await API(obj);
});
